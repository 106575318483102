/**
 * Module dependencies.
 */

import { switchProp } from 'styled-tools';
import React, {
  ElementType,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef
} from 'react';

import Rellax from 'rellax';
import styled, { css } from 'styled-components';

/**
 * Export `ParallaxFullType` type.
 */

export type ParallaxFullType = 'auto' | 'fullAbsolute' | 'fullHeight';

/**
 * `Props` type.
 */

type Props = {
  as?: ElementType;
  children: ReactNode;
  className?: string;
  fullHeight?: boolean;
  fullType: ParallaxFullType;
  percentage?: number;
  speed?: number;
  speedMobile?: number;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ fullType: ParallaxFullType }>`
  ${switchProp('fullType', {
    auto: css`
      height: auto;
    `,
    fullAbsolute: css`
      height: 100%;
      position: absolute;
      width: 100%;
    `,
    fullHeight: css`
      height: 100%;
    `
  })}
`;

/**
 * `Parallax` component.
 */

const Parallax = (props: Props): ReactElement => {
  const { children, className, fullType, percentage, speed, speedMobile } =
    props;

  const ref = useRef(null);
  const options = useMemo(() => {
    return {
      'data-rellax-mobile-speed': speedMobile,
      'data-rellax-percentage': percentage,
      'data-rellax-speed': speed,
      'data-rellax-xs-speed': speedMobile
    };
  }, [percentage, speed, speedMobile]);

  useEffect(() => {
    if (ref && ref.current) {
      const instance = new Rellax(ref.current, {
        center: true
      });

      return () => {
        instance.destroy();
      };
    }
  }, []);

  return (
    <Wrapper
      {...options}
      className={className}
      fullType={fullType ?? 'auto'}
      ref={ref}
    >
      {children}
    </Wrapper>
  );
};

/**
 * Export `Parallax` component.
 */

export default Parallax;
