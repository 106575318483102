/**
 * Export `normalizeNewLine`.
 */

export function normalizeNewLine(value: string | null | undefined): string | null {
  if (!value) {
    return null;
  }

  return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
}
