/**
 * Module dependencies.
 */

import { isExternalUrl } from 'src/core/utils/url';
import { switchProp } from 'styled-tools';
import React, { ReactElement, ReactNode } from 'react';
import RouterLink from './router-link';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  colorTheme?: ColorTheme;
  href: string;
};

/**
 * Export `ColorTheme` type.
 */

export type ColorTheme = 'dark' | 'darkWhite' | 'light';

/**
 * `Link` styled component.
 */

const Link = styled.a.attrs(({ href }) => ({
  as: (href && !isExternalUrl(href) && RouterLink) || 'a'
}))<Props>`
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-futura-pt-bold);
  font-size: 15px;
  letter-spacing: 12px;
  line-height: 20px;
  padding-bottom: 14px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  width: max-content;

  &::after {
    background-color: var(--color-yellow);
    bottom: 0;
    content: '';
    height: 6px;
    left: 0;
    position: absolute;
    transform-origin: bottom left;
    transition: width 0.3s ease-in-out;
    width: 35px;
    will-change: width;

    ${switchProp('colorTheme', {
    darkWhite: css`
        background-color: var(--color-white);
      `
  })}
  }

  &:focus,
  &:hover {
    &::after {
      width: calc(100% - 12px);
    }
  }

  ${switchProp('colorTheme', {
    dark: css`
      color: var(--color-black-800);
    `,
    darkWhite: css`
      color: var(--color-black-800);
    `,
    light: css`
      color: var(--color-white);
    `
  })}
`;

/**
 * `UnderlineLink` component.
 */

const UnderlineLink = (props: Props): ReactElement => {
  const { children, colorTheme = 'dark', href } = props;

  return (
    <Link
      colorTheme={colorTheme}
      href={href}
      {...(href &&
        isExternalUrl(href) && {
        rel: 'noopener',
        target: '_blank'
      })}
    >
      {children}
    </Link>
  );
};

/**
 * Export `UnderlineLink` component.
 */

export default UnderlineLink;
