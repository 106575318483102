/**
 * Module dependencies.
 */

import { Element } from 'react-scroll';
import { Text, clampCalc } from 'src/components/core/text';
import { media } from 'src/styles/media';
import { normalizeNewLine } from 'src/core/utils/string';
import { useLocalizedRoute } from 'src/core/utils/routes';
import Container from 'src/components/core/layout/container';
import FadeInUpAnimation from 'src/components/core/animations/fade-in-up';
import Image from 'src/components/core/image';
import Parallax from 'src/components/core/animations/parallax';
import RawHtml from 'src/components/core/raw-html';
import UnderlineLink from 'src/components/core/links/underline-link';
import styled from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  name: string;
};

/**
 * `Section` styled component.
 */

const Section = styled(Element).attrs({ as: 'section' })<Props>`
  background-color: var(--color-yellow);
  padding: 54px 0 33px;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  ${media.min.md`
    display: grid;
    grid-template-areas: 'content content content picture';
    grid-template-columns: 1fr 4fr 1fr 6fr;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  ${media.min.md`
    grid-area: content;
    padding: 95px 0 66px;
  `}
`;

/**
 * `InnerContent` styled component.
 */

const InnerContent = styled.div`
  ${media.min.md`
    margin: 0 auto;
    max-width: 66%;
  `}
`;

/**
 * `TitleText` styled component.
 */

const TitleText = styled(Text)`
  color: var(--color-dark);
  font-size: clamp(52px, ${clampCalc(52, 95)}, 95px);
  letter-spacing: clamp(8.25px, ${clampCalc(8.25, 14.25)}, 14.25);
  line-height: clamp(52px, ${clampCalc(52, 95)}, 95px);
  margin-bottom: 1rem;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: 374px) {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
`;

/**
 * `SubTitleText` styled component.
 */

const SubTitleText = styled(Text)`
  color: var(--color-white);
  margin-bottom: 4rem;
  text-align: center;

  ${media.min.md`
    margin-bottom: 7rem;
  `}

  @media screen and (max-width: 374px) {
    font-size: 3rem;
  }
`;

/**
 * `ParagraphText` styled component.
 */

const ParagraphText = styled(Text).attrs({
  as: 'p',
  variant: 'paragraph'
})`
  color: var(--color-dark);
  font-size: clamp(16px, ${clampCalc(16, 18)}, 18px);
  letter-spacing: clamp(1.6px, ${clampCalc(1.6, 1.8)}, 1.8px);
  line-height: 1.625rem;
  margin-bottom: 3rem;
`;

/**
 * `Header` styled component.
 */

const Header = styled(Text)`
  color: var(--color-dark);
  font-size: clamp(18px, ${clampCalc(18, 20)}, 20px);
  letter-spacing: clamp(8.1px, ${clampCalc(8.1, 9)}, 9px);
  line-height: clamp(18px, ${clampCalc(18, 20)}, 20px);
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`;

/**
 * `HeaderTitles` styled component.
 */

const HeaderTitles = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-area: header;
  margin-bottom: 2.5rem;
`;

/**
 * `ButtonGroup` styled component.
 */

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  ${media.min.md`
    column-gap: 2rem;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

/**
 * `Picture` styled component.
 */

const Picture = styled.div`
  padding-bottom: 102%;
  position: relative;

  ${media.min.md`
    grid-area: picture;
  `}
`;

/**
 * `FrameWrapper` styled component.
 */

const FrameWrapper = styled.div`
  height: 70%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;

  ${media.min.md`
    height: 500px;
    max-width: 100%;
    width: 500px;
  `}

  ${media.min.xl`
    height: 612px;
    width: 612px;
  `}
`;

/**
 * `Frame` styled component.
 */

const Frame = styled.div`
  background: var(--color-dark);
  border-radius: 50%;
  height: 100%;
`;

/**
 * Export `TripToPortugalSection` component.
 */

const TripToPortugalSection = ({ name }: Props) => {
  const { locale, translate } = useTranslate();
  const routeResolver = useLocalizedRoute();

  return (
    <>
      <Section name={name}>
        <Container>
          <Grid>
            <Content>
              <FadeInUpAnimation
                options={{
                  distance: '100%',
                  transitionTime: 1
                }}
              >
                <TitleText as={'h2'} variant={'titleS'}>
                  {translate(`common:sections.tripToPortugalSection.title`)}
                </TitleText>
              </FadeInUpAnimation>

              <FadeInUpAnimation
                options={{
                  distance: '100%',
                  transitionTime: 1
                }}
              >
                <SubTitleText as={'p'} variant={'handwrittenL'}>
                  {translate(`common:sections.tripToPortugalSection.subTitle`)}
                </SubTitleText>
              </FadeInUpAnimation>

              <InnerContent>
                <FadeInUpAnimation
                  options={{
                    distance: '100%',
                    transitionTime: 1
                  }}
                >
                  <HeaderTitles>
                    <Header as={'p'} variant={'titleXXS'}>
                      {translate(`common:sections.tripToPortugalSection.labels.newWriters`)}
                    </Header>

                    <Header as={'p'} variant={'titleXXS'}>
                      {translate(`common:sections.tripToPortugalSection.labels.placesRevisited`)}
                    </Header>

                    <Header as={'p'} variant={'titleXXS'}>
                      {translate(`common:sections.tripToPortugalSection.labels.writtenView`)}
                    </Header>
                  </HeaderTitles>
                </FadeInUpAnimation>

                <FadeInUpAnimation
                  options={{
                    distance: '100%',
                    transitionTime: 1
                  }}
                >
                  <RawHtml element={ParagraphText}>
                    {normalizeNewLine(translate('common:sections.tripToPortugalSection.description'))}
                  </RawHtml>
                </FadeInUpAnimation>

                <FadeInUpAnimation
                  options={{
                    distance: '100%',
                    transitionTime: 1
                  }}
                >
                  <ButtonGroup>
                    <UnderlineLink colorTheme={'darkWhite'} href={routeResolver('tripToPortugal')}>
                      {translate(`common:actions.knowMore`)}
                    </UnderlineLink>

                    <UnderlineLink
                      colorTheme={'darkWhite'}
                      href={'https://www.josesaramago.org/produto/viagem-a-portugal-journey-to-portugal-revisited/'}
                    >
                      {translate(`common:actions.buyNow`)}
                    </UnderlineLink>
                  </ButtonGroup>
                </FadeInUpAnimation>
              </InnerContent>
            </Content>

            <Picture>
              <FrameWrapper>
                <Parallax fullType={'fullHeight'} speed={3} speedMobile={2}>
                  <Frame />
                </Parallax>
              </FrameWrapper>

              <Parallax fullType={'fullAbsolute'} speed={1}>
                <Image
                  alt={translate(`homepage:sections.tripToPortugalBookSection.title`)}
                  layout={'fill'}
                  objectFit={'contain'}
                  src={`/images/new-book-${locale}.png`}
                />
              </Parallax>
            </Picture>
          </Grid>
        </Container>
      </Section>
    </>
  );
};

/**
 * Export `TripToPortugalSection` component.
 */

export default TripToPortugalSection;
